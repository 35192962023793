/*@ngInject*/
function ReindexController(SETTINGS, jobSubmit, $mdDialog, $timeout, $http, _, userProfile, $log) {
    this.profile = userProfile;

    this.submitting = false;
    this.success = false;
    this.error = null;

    this.$onChanges = (changesObj) => {
        if (changesObj.job) {
            this.submitting = false;
            this.success = false;
            this.error = null;
        }
    };



    this.reIndex = () => {
        this.submitting = true;
        this.success = false;
        this.failure = false;

        this.onStarted();

        this.job.$reIndex().then(() => {
            this.success = true;
            this.submitting = false;
        }).catch((err) => {
            this.error = err;
            this.submitting = false;
        }).finally(() => {
            this.onDone();
        });
    };

}

angular.module('sq.jobs.results.reindex.component', ['sq.user.auth', 'sq.jobs.submit.service', 'sq.user.profile.service'])
    .component('sqReindex', {
        bindings: {
            // The submission object (could be search or main job submission)
            job: '<',
            onStarted: '&',
            onDone: '&',
        }, // isolate scope
        templateUrl: 'jobs/results/jobs.results.reindex.tpl.html',
        controller: ReindexController,
        controllerAs: '$ctrl',
        // transclude: {
        //   headerActions : '?headerActions',
        //   body : '?cardContent',
        // },
    });